<template>
  <div class="card card-custom">
    <div class="card-header">
      <h4 class="card-title">{{ getChecklistInfo.processName }}</h4>
      <p class="card-subtitle">{{ getChecklistInfo.description }}</p>
    </div>
    <template v-if="getChecklistInfo.visibleType">
      <div class="card-body">
        <template
          v-if="
            getChecklistInfo.relatesProcess_id !== null &&
              !checkEmpty(getChecklistInfo.mainProc)
          "
        >
          <h6 class="card-title">
            Тест с заключением специалиста:
            {{ getChecklistInfo.mainProc.process.processName }} заполнил
            {{
              `${getChecklistInfo.mainProc.User.first_name} ${getChecklistInfo.mainProc.User.last_name} (${getChecklistInfo.mainProc.User.username})`
            }}
          </h6>
          <b-list-group>
            <b-list-group-item
              v-for="answer in getChecklistInfo.mainProc.answers"
              :key="answer.id"
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <b-badge variant="success" pill>{{ answer.task.type }}</b-badge>
                {{ answer.task.name }}:
              </div>
              <b-badge
                variant="primary"
                pill
                v-if="answer.task.type === 'Дата'"
                >{{ parseTime(answer.answer, "dd.mm.yyyy") }}</b-badge
              >
              <template v-else-if="answer.task.type === 'Множественный выбор'"
                ><b-badge
                  variant="primary"
                  pill
                  v-for="qa in answer.answers"
                  :key="qa"
                  >{{ qa }}</b-badge
                ></template
              >
              <img
                v-else-if="
                  answer.task.type === 'Графический ответ' ||
                    answer.task.type === 'Фото'
                "
                :src="drawImage(answer.answer)"
                style="max-width:50%;"
              />
              <template v-else-if="answer.task.type === 'Сотрудник'">
                <b-badge
                  variant="primary"
                  pill
                  v-for="qa in answer.answers"
                  :key="qa.id"
                  >{{ qa.first_name }} {{ qa.last_name }} ({{
                    qa.username
                  }})</b-badge
                >
              </template>
              <b-badge variant="primary" pill v-else>{{
                answer.answer
              }}</b-badge>
            </b-list-group-item>
          </b-list-group>
          <h6 class="card-title">
            Оформите заключение по итогам тестирования
          </h6>
        </template>

        <Inspection
          v-if="!checkEmpty(checklist)"
          :process="getChecklistInfo"
          :inspection="checklist"
        ></Inspection>

        <div
          class="card bg-light-primary mt-3"
          v-if="!checkEmpty(getChecklistInfo.mainProc)"
        >
          <div class="card-body">
            <div class="row">
              <label class="col-12"
                >Статус заключения
                <span :class="{ 'text-danger': status === '' }">*</span></label
              >
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-radio-group v-model="status" buttons>
                  <b-form-radio value="Прием" button-variant="outline-danger">
                    Рекомендован прием специалиста
                  </b-form-radio>
                  <b-form-radio
                    value="Консультация"
                    button-variant="outline-warning"
                  >
                    Рекомендована консультация специалиста
                  </b-form-radio>
                  <b-form-radio
                    value="Отлично"
                    button-variant="outline-success"
                  >
                    У вас не выявлено отклонений
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="form-group" v-if="getUuid !== null && mailgroups.length">
          <div class="row">
            <div class="col-12">
              <b-form-group
                label="Выбор групп рассылок"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox
                  v-model="selectAllMailgroups"
                  value="all"
                  size="lg"
                  switch
                  >Выбрать все</b-form-checkbox
                >
                <b-form-checkbox-group
                  id="select-mailgroups"
                  v-model="selectedMailgroups"
                  :aria-describedby="ariaDescribedby"
                  name="select-mailgroups"
                >
                  <b-form-checkbox
                    v-for="mailgroup in mailgroups"
                    :key="mailgroup.id"
                    :value="mailgroup.id"
                    size="lg"
                  >
                    {{ mailgroup.key }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
              <span class="form-text text-muted"
                >Выберите группы рассылок, кого уведомить о вашем ответе</span
              >
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <button
              v-on:click="submit"
              class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-submit"
            >
              Отправить
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="card-body">
        <Byone
          v-if="!checkEmpty(checklist)"
          :process="getChecklistInfo"
          :inspection="checklist"
        ></Byone>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  SET_CHECKLIST,
  SAVE_CHECKLIST
} from "@/core/services/store/checklist.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";

import Inspection from "./components/Inspection";
import Byone from "./components/Byone";

export default {
  name: "checklist-operate",
  components: {
    Inspection,
    Byone
  },
  data() {
    var d = new Date();
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var today = [year, month, day].join("-");
    return {
      mailgroups: [],
      selectedMailgroups: [],
      today: today,
      status: ""
    };
  },

  computed: {
    ...mapGetters([
      "layoutConfig",
      "getChecklistInfo",
      "getCommonInfo",
      "isChecklistLoaded",
      "getUuid"
    ]),

    config() {
      return this.layoutConfig();
    },

    checklist: {
      get: function() {
        return this.$store.getters.getCheckList;
      },
      set: function(checklist) {
        this.$store.commit(SET_CHECKLIST, checklist);
      }
    },

    selectAllMailgroups: {
      get: function() {
        return this.mailgroups
          ? this.selectedMailgroups.length == this.mailgroups.length
          : false;
      },
      set: function(value) {
        var selectedMailgroups = [];
        if (value) {
          this.mailgroups.forEach(function(role) {
            selectedMailgroups.push(role.id);
          });
        }

        this.selectedMailgroups = selectedMailgroups;
      }
    }
  },

  created() {
    if (!this.isChecklistLoaded) {
      //console.log(this.isChecklistLoaded);
      Swal.fire({
        title: "",
        text: "Пожалуйста, выберите тест еще раз!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      }).then(() => {
        this.$router.push("/checklist/all");
      });
    } else {
      this.loadMailgroups();
      //console.log(this.getCommonInfo);
      // console.log(this.checklist);

      //load mainProc if empty - overkill if, probably should be removed
      /*if (
        this.checkEmpty(this.getChecklistInfo.mainProc)
      ) {
        this.loadMainProc();
      }*/
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Заполнение" },
      { title: "Тесты", route: "/checklist/all" }
    ]);
  },

  methods: {
    drawImage(src) {
      if (!src.includes(this.$host)) {
        src = this.$host + src;
      }
      return src;
    },

    async loadMainProc() {
      //   console.log(this.getChecklistInfo.relatesProcess_id);
      try {
        return new Promise(resolve => {
          ApiService.post("api/v1/ra2/", {
            status_id: this.getChecklistInfo.relatesProcess_id
          }).then(({ data }) => {
            // console.log(data);
            this.getChecklistInfo.mainProc = data;
            this.timeSpent =
              new Date(data.date_end) - new Date(data.date_begin);
            data.answers.sort((a, b) => a.id - b.id);
            resolve(data);
          });
        });
      } catch ({ response }) {
        console.error(response);
      }
    },

    async loadMailgroups() {
      try {
        return new Promise(resolve => {
          ApiService.post("api/v1/findCustomData/", {
            user_id: this.$store.getters.currentUser.user.id,
            type: "mailer"
          }).then(({ data }) => {
            this.mailgroups = data;
            resolve(data);
          });
        });
      } catch ({ response }) {
        console.error(response);
      }
    },

    validateQuestions(arr) {
      var retarr = [];
      arr.forEach(quest => {
        if (quest.answers.length > 0) {
          if (
            quest.answer !== null &&
            typeof quest.answer !== "undefined" &&
            quest.answer !== [] &&
            quest.answer !== ""
          ) {
            quest.answers.forEach(a => {
              if (
                a.hasOwnProperty("questions") && Array.isArray(quest.answer)
                  ? quest.answer.includes(a.id)
                  : a.id === quest.answer
              ) {
                // console.log("we are here");
                //  console.log(a.questions);
                if (typeof a.questions !== "undefined") {
                  retarr = retarr.concat(
                    a.questions,
                    this.validateQuestions(a.questions)
                  );
                }
              }
            });
          }
        }
      });
      return retarr;
    },

    validateChecklist(obj) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var questions = obj.section_start.questions.concat(
        obj.section_finish.questions
      );
      obj.sections.forEach(section => {
        questions = questions.concat(section.questions);
        questions = questions.concat(this.validateQuestions(section.questions));
      });
      if (
        questions.findIndex(
          q =>
            (q.dataTypeId_id === 3 &&
              q.questName.includes("email") &&
              !re.test(q.answer)) ||
            (q.requared &&
              (q.answer === null ||
                typeof q.answer === "undefined" ||
                q.answer === [] ||
                q.answer === "" ||
                q.answer.length < 1))
        ) === -1
      ) {
        return true;
      } else {
        return false;
      }
    },

    submit: function(e) {
      e.preventDefault();
      //validate before saving to server
      // console.log(this.validateChecklist(this.checklist));
      if (this.validateChecklist(this.checklist)) {
        this.$store
          .dispatch(SAVE_CHECKLIST, {
            mailgroups: this.selectedMailgroups,
            status: this.status
          })
          .then(() => {
            Swal.fire({
              title: "",
              text: "Тест успешно добавлен!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
            this.$router.push("/checklist/all");
          })
          .catch(error => {
            console.error(error);
            Swal.fire({
              title: "",
              text: "Произошла ошибка, повторите попытку!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            });
          });
      } else {
        Swal.fire({
          title: "",
          text: "Заполните обязательные поля, отмеченные *!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      }
    },

    findObjectByKeyVal: function(obj, key, val) {
      if (!obj || typeof obj === "string") {
        return null;
      }
      if (obj[key] === val) {
        return obj;
      }

      for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
          var found = this.findObjectByKeyVal(obj[i], key, val);
          if (found) {
            return found;
          }
        }
      }
      return null;
    },

    checkEmpty(obj) {
      return KTUtil.isEmpty(obj);
    },

    parseTime(datetime, format = "dd.mm") {
      var newdate = new Date(datetime);
      var dd = String(newdate.getDate()).padStart(2, "0");
      var mm = String(newdate.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = newdate.getFullYear();
      //var yy = yyyy.toString().substr(-2);
      //return dd + '.' + mm + '.' + yyyy;
      if (format === "dd.mm") {
        return dd + "." + mm;
      }
      if (format === "dd-mm-yyyy") {
        return dd + "-" + mm + "-" + yyyy;
      }
    }
  }
};
</script>
